// forms
.selected {
    border-bottom: 2px solid $dark-gray;
}
//textarea {
//    min-height:3em;
//}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    border: 0 solid transparent;
    border-bottom: 1px dotted $medium-gray;
    color: $medium-gray;
    box-shadow: none;
    background-color:transparent;
}

[type='text']:focus,
[type='password']:focus,
[type='date']:focus,
[type='datetime']:focus,
[type='datetime-local']:focus,
[type='month']:focus,
[type='week']:focus,
[type='email']:focus,
[type='number']:focus,
[type='search']:focus,
[type='tel']:focus,
[type='time']:focus,
[type='url']:focus,
[type='color']:focus,
textarea:focus {
    background-color:transparent;
    outline: none;
    border: 0;
    border-bottom: 1px solid #303030;
    background-color: #ffffff;
    box-shadow: none;
}
select {
    height: 1.6375rem;
    margin: 0 0 0.5rem;
    padding: 0.2rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    border: 1px solid #9c9c9c;
    border-radius: 2px;
    background-color: #ffffff;
    font-family: inherit;
    font-size: 1rem;
    line-height: normal;
    color: #313131;
}

#search {
    i {
        color: $dark-gray;
        -ms-transform: rotate(90deg);
        /* IE 9 */
        -webkit-transform: rotate(90deg);
        /* Chrome, Safari, Opera */
        transform: rotate(90deg);
        margin-right: 0.2em;
        &:hover {
            color: $hardroze;
        }
    }
    .input-group-field {
        color: $dark-gray;
        font-size: 1.5625rem;
    }
}

//input#thumbnailUpload {
// width: 120px;
// height: 120px;
// cursor: pointer;
//}
//
//.imageUpload {
// position: relative;
// box-sizing: border-box;
// border: 2px solid $medium-gray;
// background-color: $medium-gray;
// width: 100px;
// height: 120px;
// cursor: pointer;
// &::after {
//  content: "\f093";
//  font-family: FontAwesome;
//  font-size: 64px;
//  opacity: 0.3;
//  color: $white;
//  position: absolute;
//  left: 50%;
//  top: 46%;
//  transform: translate(-50%, -50%);
// }
//}
//#activitybuilder select,
//#activitybuilder select:active,
//#activitybuilder select:focus,
//#activitybuilder select:hover {
//    //display:inline block;
//    box-sizing: border-box;
//    border: 2px solid $hardroze;
//    box-shadow: 0;
//    width: 220px;
//    //height:110px;
//    &:active {
//        border-color: $hardroze;
//    }
//    &:focus {
//        border-color: $paars;
//    }
//    &:hover {
//        border-color: $hardroze;
//    }
//}

select, option,
select:active,
select:focus,
select:hover {
    font-size:1em;
    &:active {
        border-color: $hardroze;
    }
    &:focus {
        border-color: $paars;
    }
    &:hover {
        border-color: $hardroze;
    }
}

//input,
//input:focus,
//input:active,
//input:hover {
//    border-top:0;
//    border-right:0;
//    border-left:0;
//}
input:focus::-webkit-input-placeholder {
    color: transparent;
}

input:focus:-moz-placeholder {
    color: transparent;
}


/* FF 4-18 */

input:focus::-moz-placeholder {
    color: transparent;
}


/* FF 19+ */

input:focus:-ms-input-placeholder {
    color: transparent;
}


/* IE 10+ */

.input-group-label {
    text-align: center;
    padding: 0 1em 0 0;
    background: transparent;
    color: $medium-gray;
    border: 0px;
    white-space: nowrap;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
}

[type='text'],
[type='password'],
[type='date'],
[type='datetime'],
[type='datetime-local'],
[type='month'],
[type='week'],
[type='email'],
[type='number'],
[type='search'],
[type='tel'],
[type='time'],
[type='url'],
[type='color'],
textarea {
    //border-color: $hardroze;
}

fieldset.filter label {
    float: left;
    margin-right: 0.2em;
}

[type="file"],
[type="checkbox"],
[type="radio"] {
    margin: 0;
    padding: 0;
}

.fieldset {
    border: 0;
    padding: 0;
    margin: 0;
}

.input-group >:first-child {
    border-radius: 0;
}

.close-button {
    font-size: 2em;
    font-weight: bold;
    line-height: 1;
    cursor: pointer;
    background-color: transparent;
}

.button {
    @include box-shadow($shadow-1dp);
    &:hover {
        @include box-shadow($shadow-3dp);
    }
    &:active {
        @include box-shadow($shadow-1dp);
    }
}

[contenteditable="true"] {
    cursor: text;
    outline: 1px dashed $medium-gray;
//    &::before {
//        font-family: FontAwesome;
//        content: '\f040';
//        color: $dark-gray;
//        margin-right: 6px;
//    }
    &:hover {
        color:$black;
    }
    &:focus,
    &:active {
        outline: 1px dashed $hardroze;
        color:$black;
    }
}

img[contenteditable="true"] {
    cursor: pointer;
    position: relative;
    //box-sizing: border-box;
    height: 160px;
    &::after {
        content: "\f093";
        font-family: FontAwesome;
        font-size: 2em;
        //color: $cyaan;
        background-color: $white;
        position: absolute;
        left: 50%;
        top: 46%;
        transform: translate(-50%, -50%);
        padding:0.1em 0.3em;
    }
    &:hover {
        font-family: FontAwesome;
        content: '\f05d';
    }
}

img.upload-image {
    height: 160px;
    background-image: url("/assets/img/Caras-y-Caretas-Squared.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}



#search .input-group {
    margin-bottom: 0rem;
}
