// TYPOGRAPHY
//* {
//    //text-align: justify;
//
//    -ms-word-break: break-all;
//    word-break: break-all;
//    // Non standard for webkit
//    word-break: break-word;
//
//    -webkit-hyphens: auto;
//    -moz-hyphens: auto;
//    hyphens: auto;
//    hyphenate-before: 2;
//    hyphenate-after: 3;
//    hyphenate-lines: 3;
//
//    -webkit-hyphenate-character:"\2010";
//    -webkit-hyphenate-limit-after:1;
//    -webkit-hyphenate-limit-before:3;
//
//    word-spacing: 0 -0.05em 1em; /* optimum, minimum, maximum respectively */
//    letter-spacing: 0 -0.01em 0.02em;
//}




.section-title {
    border-bottom: solid 2px;
    margin-bottom: 0.5em;
    letter-spacing: -0.02em;
    color: $medium-gray;
}

.page-title {
    border-bottom: solid 1px $medium-gray;
    margin-bottom: 0.5em;
    text-align: center;
}

.paragraph {
    border-bottom: 1px solid;
    padding-bottom: 0.2em;
    margin-bottom: 0.5em;
    margin-top: 2em;
}

.seq-title {
    margin-top: 1em;
    margin-bottom: 1em;
}

.margin-top {
    margin-top: 2em;
}

.bb-type {
    margin-top: 1em;
    margin-bottom: 0.5em;
}

.bb-title {
    margin-bottom: 0.5em;
}

#browse {
    //font-size: 2.90698rem; //h3
    //font-size: 1.81686rem; //h4
    //font-size: 1.45349rem; //h5
}
