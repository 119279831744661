@mixin box-shadow($shadow-1dp) {
    -webkit-box-shadow: $shadow-1dp;
    -moz-box-shadow:    $shadow-1dp;
    -o-box-shadow:      $shadow-1dp;
    box-shadow:         $shadow-1dp;
}

@mixin box-shadow($shadow-3dp) {
    -webkit-box-shadow: $shadow-3dp;
    -moz-box-shadow:    $shadow-3dp;
    -o-box-shadow:      $shadow-3dp;
    box-shadow:         $shadow-3dp;
}

@mixin box-shadow($shadow-6dp) {
    -webkit-box-shadow: $shadow-6dp;
    -moz-box-shadow:    $shadow-6dp;
    -o-box-shadow:      $shadow-6dp;
    box-shadow:         $shadow-6dp;
}

@mixin box-shadow($shadow-12dp) {
    -webkit-box-shadow: $shadow-12dp;
    -moz-box-shadow:    $shadow-12dp;
    -o-box-shadow:      $shadow-12dp;
    box-shadow:         $shadow-12dp;
}

@mixin box-shadow($shadow-24dp) {
    -webkit-box-shadow: $shadow-24dp;
    -moz-box-shadow:    $shadow-24dp;
    -o-box-shadow:      $shadow-24dp;
    box-shadow:         $shadow-24dp;
}

@mixin transition($transition-slow) {
    -webkit-transition: $transition-slow;
    -moz-transition:    $transition-slow;
    -o-transition:      $transition-slow;
    transition:         $transition-slow;
}
@mixin transition($transition-fast) {
    -webkit-transition: $transition-fast;
    -moz-transition:    $transition-fast;
    -o-transition:      $transition-fast;
    transition:         $transition-fast;
}

//@mixin scale($number) {
//    z-index:10;
//    background-color:$white;
//    -webkit-transform:  scale($number);
//    -moz-transform:     scale($number);
//    -ms-transform:      scale($number);
//    -o-transform:       scale($number);
//    transform:          scale($number);
//    transform-origin: top left;
//}

