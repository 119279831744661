/* ===== PREVENT SCROLLBAR JUMP WHEN SWITCING PAGES ===== */

html {
    overflow-y: scroll;
}
body {
    @include transition($transition-fast);
}


/*===== MAIN STYLES =====*/


section {
    padding-top: 1rem;
    padding-bottom: 1rem;
    & + section {
        margin-top:1px;
    }

}


/* to prevent jump in page when using reveal use next 6 lines */

//body.is-reveal-open {
//    overflow: visible;
//}
//
//.reveal-overlay {
//    overflow-y: hidden;
//}


/* ===== LOGIN ===== */

//#login_modal,
//#register_modal {
//    background-color: $hardroze;
//    color: $white;
//    a,
//    label,
//    .close-button {
//        color: $white;
//    }
//    .button {
//        margin-top: 2em;
//        background-color: $white;
//    }
//}


/* ===== START ANIMATE ARROWS IN CALLOUT BOXES ON HOMEPAGE ===== */

.is-homepage .callout {
    margin-bottom: 1em;
    display: block;
    border:0;
//    &:hover {
//        position: relative;
//        animation-name: arrowright;
//        animation-duration: 4s;
//    }
    i {
        float: right;
    }
    a {
        color:$white;
        text-transform: uppercase;
        font-weight: 500;
        font-weight: 500;
        letter-spacing: 0px;
        margin: 1em 0;
    }
}

//@keyframes arrowright {
//    0% {
//        left: 0px;
//        top: 0px;
//    }
//    //25%  {left:200px; top:0px;}
//    //50%  {left:200px; top:200px;}
//    //75%  {left:0px; top:200px;}
//    10% {
//        left: 10px;
//        top: 0px;
//    }
//}



/*===== big texton homepage =====*/

#Historiana-intro {
    margin-top: 2em;
    margin-bottom: 3em;
    a {
        color: $primary-color;
    }
}


/*===== FIXES =====*/

.dropdown-pane {
    font-size: 1em;
    color: $black;
//    -webkit-transition: all 0.3s ease;
//  transform: translateY(-30%);
}

.menu,
.menu.simple {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}


/*===== UI EFFECTS =====*/

.change-icon > .fa + .fa,
.change-icon:hover > .fa {
    display: none;
}

.change-icon:hover > .fa + .fa {
    display: inherit;
}


//hr.spacer {
//    border-bottom: 2px $light-gray solid;
//    color: $light-gray;
//    height: 2px;
//    margin-top: 2em;
//    margin-bottom: 2em;
//}




.filter.tags {
        font-size: 90%;
        li {
            margin-bottom: 2px;
            margin-right: 2px;
        }
        a {
            background-color: $medium-gray;
            padding: 0.25rem 0.5rem;
            line-height: 1;
            color: $white;
            border-radius: 12px;
        }
    }

.notloggedin-alert {
    background-color:$hardroze;
    color:$white;
    padding:2em;
    font-weight:700;
}
