/* stylesheet for activitybuilder.html template */

body.is-activity-builder {
  height: 100%;
}


/* ---- BUILDING BLOCKS ===== */

.building-block {
  position: relative;
  width: 100px;
  margin: 0.2em;
  display: inline-block;
  vertical-align: bottom;
  text-align: center;
  @include box-shadow($shadow-3dp);
  border-radius: $global-radius;
  &::before {
    font-family: FontAwesome;
    font-size: 32px;
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
  }
  a {
    color: $white;
  }
  &:hover {
    @include box-shadow($shadow-6dp);
  }
  &:active {
    @include box-shadow($shadow-24dp);
    -webkit-transform: rotate(-2deg);
    transform: rotate(-2deg);
  }
  &.add a {
    color: $dark-gray;
  }
  &.text::before {
    content: "\f0f6";
  }
  &.question::before {
    content: "\f128";
  }
  &.embed::before {
    content: "\f0c1";
  }
  &.apps::before {
    content: "\f009";
  }
  &.sequencing::before {
    content: "\f0c0";
  }
  &.analysing::before {
    content: "\f002";
  }
  .block-title {
    font-weight: bold;
    font-size: 70%;
    //font-variant: small-caps;//
    text-transform: uppercase;
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
    padding: 0.4em 0;
    bottom: 26px;
    //    &:hover {
    //      color: $black;
    //    }
  }
  &.add-image {
    border: 0;
  }
  .bb-actions {
    position: absolute;
    bottom: 0;
    height: 24px;
    width: 100px;
    background-color: $light-gray;
    padding-top: 4px;
    padding-left: 12px;
    a:link,
    a:visited {
      color: $dark-gray;
    }
    a:hover {
      color: $hardroze;
    }
  }
}

.is-select-sources .building-block a,
.is-activity-builder .building-block a,
.is-my-historiana .building-block a,
.is-builder-basket .building-block a {
  color: $white;
}

#AB-homepage {
  background-color: $medium-gray;
  color: $white;
}

#AB-intro {
  margin-bottom: 1em;
}

.off-canvas #activitybuilder {
  margin-top: 1em;
}

#activitybuilder {
  #guidance {
    color: $white;
    //font-variant: small-caps;
    //text-transform: uppercase;
    //text-shadow: 0 0 1px $medium-gray;
    margin: 0 0 1.6em 0;
  }
  .menu.horizontal a {
    background-color: $hardroze;
    color: $white;
    box-shadow: 0 0 1px $medium-gray;
    &:hover {
      background-color: $white;
      color: $hardroze;
      border-color: $white;
      box-shadow: 0 0 0px $medium-gray;
    }
  }
  .menu.vertical a {
    color: $white;
    padding: 0.7rem 0rem;
  }
}

#drag-drop-area,
#containment-wrapper {
  position: relative;
  margin-left: 0;
  width: 100%;
  list-style: none;
  padding: 0.4em 0.4em;
}

#drag-drop-area {
  min-height: 136px;
  -moz-box-shadow: inset 0 0 20px 0px $dark-gray;
  -webkit-box-shadow: inset 0 0 20px 0px $dark-gray;
  box-shadow: inset 0 0 20px 0px $dark-gray;
  border: 1px dashed $medium-gray;
  cursor: move;
  .building-block {
    min-height: 124px;
    &::before {
      top: 40%;
    }
  }
  .building-block.dropped {
    opacity: 1;
    margin-right: 0.3em;
  }
  .block-title {
    bottom: 26px;
  }
}

#containment-wrapper {
  min-height: 122px;
  border: 1px solid $dark-gray;
  .building-block {
    min-height: 100px;
    &::before {
      top: 50%;
    }
  }
  .bb-actions {
    display: none;
  }
  .block-title {
    bottom: 0px;
  }
}

//start delete building block
#remove-bb {
  background-color: $white;
  padding: 2em;
}

.delete-this-bb {}

//#containment-wrapper .delete-this-bb {
//  display:none;
//}
//end delete building block
//Show building-blocks in a sequence as small icons
#sequence-navigator li {
  width: 36px;
  min-height: 36px;
  margin-right: 1px;
  background-color: $dark-gray;
  color: $white;
  -o-transition: 0.5s ease-out;
  -ms-transition: 0.5s ease-out;
  -moz-transition: 0.5s ease-out;
  -webkit-transition: 0.5s ease-out;
  transition: 0.5s ease-out;
  &:hover {
    background-color: $white;
    color: $medium-gray;
  }
  a {
    font-weight: 700;
    font-size: 1.2em;
    color: $white;
    padding: 0.7rem 0.7rem;
    &:hover {
      color: $dark-gray;
    }
  }
}

#nav-AB {
  background-color: $white;
  padding: 1em;
}

.ui-sortable-placeholder {
  display: inline-block;
  vertical-align: middle;
  width: 100px;
  height: 124px;
  margin: 0.2em;
  padding: 0;
  //background-color: rgba(180, 180, 180, 0.8);
  outline: 1px dotted $white;
  animation: fadein 1s;
  -moz-animation: fadein 1s;
  -webkit-animation: fadein 1s;
  -o-animation: fadein 1s;
  -webkit-transition: opacity 2.0s ease-in;
  -moz-transition: opacity 2.0s ease-in;
  -o-transition: opacity 2.0s ease-in;
  opacity: 1;
}

//.ui-sortable-helper {
//    background-color: $hardgeel;
//}
.ui-state-highlight {
  //    box-shadow: 0px 0px 5px 0px $black;
  background-color: rgba(255, 255, 255, 0.1);
}

.ui-state-hover {
  //    background-color: $white;
  box-shadow: 0px 0px 10px 0px $black;
}

.ui-state-active {
  //    background-color: $white;
  box-shadow: 0px 0px 20px 0px $black;
}

#overview-activities .column {
  padding-bottom: 0.5em;
}

#overview-activities .square-image {
  position: relative;
  width: 170px;
  height: 170px;
  padding: 0;
  display: inline-block;
  &.question {
    background: no-repeat url("/assets/img/Caras-y-Caretas-Squared.jpg") center $medium-gray;
  }
  &.text {
    background: no-repeat url("/assets/img/European_Imperialism_in_China_thumb_standard.jpg") center $medium-gray;
  }
  &.embed {
    background: no-repeat url("/assets/img/image005.jpg") center $medium-gray;
  }
  &.activity {
    background: no-repeat url("/assets/img/poster.JPG") center $medium-gray;
  }
}

#eActivities {
  margin-top: 2em;
}

.reveal {
  color: $black;
}

.object_title,
#drag-drop-area .object_title {
  color: $black;
  width: 140px;
  overflow: hidden;
  white-space: normal;
}

.preview-fullsize {
  //@include reveal-modal-fullscreen;
  @include reveal-modal-width($global-width);
  cursor: w-resize;
  padding: 36px;
}

.overlay {
  margin-top: -2em;
  .button {
    background-color: transparent;
    text-transform: uppercase;
    color: $dark-gray;
    &.use::before {
      font-family: FontAwesome;
      content: "\f096";
      padding-right: 0.5em;
    }
    &.use:hover::before {
      content: "\f046";
    }
  }
}

#seq-meta {
  margin-top: 1em;
  margin-bottom: 1em;
}

.seq-thumbnail {
  width: 100px;
  height: 100px;
  margin-right: 1em;
}


/* ===== TITLE BAR | OPEN/CLOSE SEQUENCE ===== */

.title-bar {
  padding: 0;
  a,
  .title-bar-title {
    color: $white;
    background-color: $black;
    $include: transition($transition-slow);
    &:hover {
      color: $black;
      background-color: $white;
    }
  }
  .title-bar-title {
    padding: 0.7em 1em;
    width: 100%;
    line-height: 1;
  }
  .simple a {
    padding: 0.7em 1em;
    margin: 0;
    .fa {
      padding-right: 0.4em;
      &:hover {
        color: $black;
      }
    }
  }
  .menu.simple li {
    margin-right: 0;
  }
}
