// FOOTER 
footer {
    height:100%;
    font-size: 90%;
//    margin-top: 3em;
    padding-top: 3em;
    padding-bottom: 3em;
    background-color: $dark-gray;
    color:$white;
    a {
        //color:$white;
        &:hover {
            color: $hardroze;
        }
    }
    h5 {
        //color: lighten($medium-gray, 20%);
    }
}

footer .row {
    width: 100%;
}

footer .menu > li > a {
    display: block;
    padding: 0.5rem 0rem;
    line-height: 1;
    color:$white;
}

.logo-footer {
    max-width: 220px;
    &.logo.svg {
        max-height: 40px;
    }
}

#about-historiana .menu li a {
    margin-right: 1em;
}
