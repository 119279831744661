/* ===== CARD USED IN OVERVIEWS LIKE MY HISTORIANA ===== */

.item {
  margin-bottom: 1em;
}

.homepage > .item {
  font-size: medium;
  p {
    font-size: medium;
  }
}

#card-holder {
  background-color: $medium-gray;
  width: 100%;
  padding: 2em 0;
}

.card {
  @include box-shadow($shadow-3dp);
  //transition: all 0.3s cubic-bezier(.25, .8, .25, 1);
  //    &:hover {
  //        box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.137255), 0px 5px 22px 4px rgba(0, 0, 0, 0.117647); //6dp
  //    }
  .card-section {
    //    flex-wrap: wrap;
  }
  img {
    max-height: 160px;
    min-width: 100%;
    padding-bottom: rem-calc(8);
  }
  .title {
    font-size: 1.25rem;
    line-height: 1.6em;
    -ms-word-break: break-all;
    word-break: break-all;
    // Non standard for webkit
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    height: 100%;
  }
  .description {
    resize: vertical;
    line-height: 1.6em;
    -ms-word-break: break-all;
    word-break: break-all;
    // Non standard for webkit
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
    height: 100%;
  }
  .tags {
    font-size: 80%;
    li {
      margin-bottom: 2px;
      margin-right: 2px;
    }
    a {
      color: $white;
      padding: 0.25rem 0.5rem;
      line-height: 1;
      background-color: $medium-gray;
      border-radius: 12px;
    }
  }
  .actions {
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    li {
      margin-right: 0;
    }
    a {
      color: $dark-gray;
      padding-right: 0.7em;
      -webkit-transition-duration: .2s;
      -moz-transition-duration: .2s;
      -o-transition-duration: .2s;
      transition-duration: .2s;
      -webkit-transition-timing-function: linear;
      -moz-transition-timing-function: linear;
      -o-transition-timing-function: linear;
      transition-timing-function: linear;
      &:hover {
        color: $hardroze;
      }
      &:active {}
    }
  }
  .image {
    position: relative;
    width: 100%;
  }
  .dropdown-pane.small {
    min-width: 220px;
  }
  #reveal {
    //text-align: justify;
    -ms-word-break: break-all;
    word-break: break-all;
    // Non standard for webkit
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
  .more-info {
    display: none;
  }
}


/* ===== MANAGE ===== */

.manage {
  color: $hardroze;
  i {
    color: $white;
  }
}

#manage01 {
  display: none;
  position: absolute;
  top: 20px;
  padding: 0 0.98684rem 0 0;
  background-color: transparent;
  .menu.vertical > li > a {
    background-color: $hardroze;
    color: $white;
    border-bottom: 1px solid $white;
    padding: 0.7em 1em;
    &:hover {
      background-color: $white;
      color: $hardroze;
    }
  }
}

.reveal.preview {
  #wrap {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    iframe {
      -moz-transform: scale(0.7);
      transform: scale(0.7);
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.reveal h6 {
  padding-bottom: 0.3em;
  margin-bottom: 1.5em;
  border-bottom: 1px solid;
}

.reveal.action {
  padding: 3em;
  .selections .column {
    border-left: 1px solid $white;
  }
  .add-new {
    display: inline-block;
    border: 1px solid $white;
    padding: 0.5em;
    margin-top: 1em;
    border-radius: 4px;
  }
  #columns-tags li {
    border-bottom: 1px solid $paars;
    &:hover {
      border-bottom: 1px solid $white;
    }
  }
}

// SUBTLE CLICK ANIMATIONS
