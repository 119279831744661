//$background-colors-list: #ff0000 #ff6600 #eebb00 #eeee00 #bbee00 #55bb00 #00aa44 #00aa88 #00aabb #00aaee #0066cc #1133bb #331188 #771177 #aa0099 #dd0077;
//@for $i from 1 through length($background-colors-list) {
//    .color-#{$i} {
//        background-color: nth($background-colors-list, $i);
//    }
//}


/* ===== COLORED BLOCKS ===== */

#search-europeana {
    background-color: $hardroze;
    color: $white;
}

#search-europeana h4 {
    margin-bottom: rem-calc(16);
}

#register {
    background-color: $white;
    padding-top: rem-calc(16);
}

#find-apps {
    background-color: $eigeel;
    padding-top: rem-calc(16);
}

#create-app {
    background-color: $hardgeel;
    padding-top: rem-calc(16);
}

#advanced-search a,
#field-of-interest a,
#resource-type a,
#tags a {
    background-color: transparent;
    color: $white;
}

#find-resources {
    background-color: $medium-gray;
}

.connecting,
#connecting {
    background-color: $oranje;
    color: $white;
}

.sequencing,
#sequencing,
#app-sequencing,
.is-bb-sequencing {
    background-color: $paars;
    color: $white;
}

.prioritizing,
#prioritizing {
    background-color: $blauwgroen;
    color: $white;
}

.analysing,
#analysing,
.is-bb-analysing {
    background-color: $plasticgroen;
    color: $white;
}

.type.historicalcontent,
.type.timelines,
.card #timelines,
.type.units,
.unit,
.card #units,
.type.casestudies,
.casestudy,
.card #casestudies,
.type.lifestories,
.card  #lifestories,
.type.sourcecollections,
.card #sourcecollections,
.type.keymoments,
.card  #keymoments,
.type.viewpoints,
.card  #viewpoints {
    background-color: $plasticgroen;
    color: $white;
}

#content-block-question,
.question,
.is-bb-question {
    background-color: $eigeel;
    color: $white;
}

#content-block-text,
.building-block.text,
#bb-text,
.is-bb-text {
    background-color: $hardroze;
    color: $white;
}

#content-block-embed,
.embed,
.is-bb-embed {
    background-color: $oranje;
    color: $white;
}

.type.learningactivities,
.card  #learningactivities,
.type.teachingstrategies,
.card #teachingstrategies,
.type.apps,
.card #apps,
{
    background-color: $blauwgroen;
    color: $white;
}

.ela,
{
    background-color: $hardroze;
    color: $white;
}

.building-block.apps {
    background-color: $hardroze;
    color: $white;
}
.add {
    border:1px solid $dark-gray;
    color:$dark-gray;
}
//.excbb, .add {
//    background-color: $dark-gray;
//    color: $white;
//}
.is-homepage .blog a {
    color: $black;
}

.is-homepage .callout.historicalcontent {
    background-color: $plasticgroen;
    color:$white;
    &:hover {
        background-color: lighten($plasticgroen, 10%)
    }
}

.is-homepage .callout.teachingandlearning {
    background-color: $blauwgroen;
    color:$white;
    &:hover {
        background-color: lighten($blauwgroen, 10%)
    }
}

.is-homepage .callout.searchcollections,
.is-homepage .callout.createapps {
    background-color: $hardroze;
    color:$white;
    &:hover {
        background-color: lighten($hardroze, 30%);
      color:$black;
    }
}
.is-homepage .callout.direction {
    background-color: $cyaan;
    color:$white;
    &:hover {
        background-color: lighten($cyaan, 10%)
    }
}
//.is-my-historiana .selected {
//    border-color: $hardroze;
//}

//.is-select-sources a,
//.is-activity-builder a,
//.is-my-historiana a,
//.is-builder-basket a {
//    color: $hardroze;
//    &:hover {
//        color:darken($hardroze, 10%);
//    }
//}
.source {
    background-color: $dark-gray;
    color: $white;
}


/* ===== SECTIONS ===== */

#subnav {
    background-color: $light-gray;
}
#search {
    background-color: $light-gray;
}
#browse {
    background-color: $light-gray;
}
#filter {
    background-color: $light-gray;
    color:$black;
}
#upload-sources {
    background-color: $light-gray;
}
