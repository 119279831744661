// ===== HEADER MAIN MENU
header {
    //        margin-top: 10px;
    font-size: 90%;
    //border-bottom: 1px dotted $medium-gray;
    padding-top: 2em;
    padding-bottom: 2em;
}

#logo {
    margin-right: 8px;
    padding-left: 0.98684rem;
    width: 100px;
    max-width: 100px;
    height: 100px;
    overflow: hidden;
}

.logo.svg {
    height: 100px;
    margin: 0px;
    padding: 0px;
    path {
        fill: $medium-gray;
        color: $medium-gray;
    }
    &:hover path {
        fill: $light-gray;
        color: $light-gray;
        @include transition-basics(0.5s, all, 0s);
    }
}

body.is-homepage .logo.svg path {
    fill: $cyaan;
    color: $cyaan;
    @include transition-basics(0.5s, all, 0s);
}

body.is-historical-content .logo.svg path {
    fill: $plasticgroen;
    color: $plasticgroen;
    @include transition-basics(0.5s, all, 0s);
}

body.is-teaching-learning .logo.svg path {
    fill: $blauwgroen;
    color: $blauwgroen;
}

body.is-my-historiana .logo.svg path,
body.is-activity-builder .logo.svg path {
    fill: $hardroze;
    color: $hardroze;
}

//#sub-menu {
//    text-transform: uppercase;
//    font-weight: 300;
//    letter-spacing: 1px;
//    padding-right: 0.98684rem;
//    .menu li {
//        background-color: $light-gray;
//    }
//    li > a {
//        color: $dark-gray;
//        &:hover {
//            background-color: invert($dark-gray);
//            //color: $dark-gray;
//            @include transition-basics(0.3s, all, 0s);
//        }
//    }
//}
.menu {
    margin: 0 auto;
}

#main-menu {
    ul.menu > li {
        position: relative;
        width: 100px;
        height: 100px;
        margin-right: 8px;
        margin-bottom: 0px;
        border-radius: $global-radius;
        border: 1px dotted lighten($medium-gray, 20%);
    }
    li > a {
        display: block;
        height: 100%;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 80%;
        color: $dark-gray;
        &.historical-content:hover {
            background-color: $plasticgroen;
            color: $white;
            border-color: $plasticgroen;
            @include transition-basics(.4s, linear, 0s);
        }
        &.teaching-learning:hover {
            background-color: $blauwgroen;
            border-color: $blauwgroen;
            color: $white;
            @include transition-basics(.4s, linear, 0s);
        }
        &.select-sources:hover,
        &.activity-builder:hover,
        &.my-historiana:hover,
        &.builder-basket:hover {
            background-color: $hardroze;
            border-color: $hardroze;
            @include transition-basics(.4s, linear, 0s);
        }
        &.historical-content.selected {
            background-color: $plasticgroen;
            color: $white;
            border-color: $plasticgroen;
            @include transition-basics(.4s, linear, 0s);
        }
        &.teaching-learning.selected {
            background-color: $blauwgroen;
            border-color: $blauwgroen;
            color: $white;
            @include transition-basics(.4s, linear, 0s);
        }
        &.select-sources.selected,
        &.activity-builder.selected,
        &.my-historiana.selected,
        &.builder-basket.selected {
            background-color: $hardroze;
            border-color: $hardroze;
            @include transition-basics(.4s, linear, 0s);
        }
        &.selected,
        &.selected:before {
            color: $white;
            @include transition-basics(.2s, linear, 0s);
        }
        &:before {
            font-family: 'FontAwesome';
            font-size: 16px;
            font-weight: 300;
            color: $dark-gray;
            opacity: 1;
            position: absolute;
            left: 8px;
            top: 40px;
            //transform: translate(-50%, -50%);
            //cursor: move;
        }
        &.historical-content:before {
            content: "\f19c";
        }
        &.teaching-learning:before {
            content: "\f0c0";
        }
        &.select-sources:before {
            content: "\f03e";
        }
        &.activity-builder:before {
            content: "\f009";
        }
        &.my-historiana:before {
            content: "\f007";
        }
        &.builder-basket:before {
            content: "\f00a";
        }
        &.builder-basket .badge {
            position: absolute;
            bottom: 10px;
            right: 4px;
            padding: 0.3em;
            min-width: 2.1em;
            height: 2.1em;
            font-size: 1em;
            line-height: 1.4em;
            font-weight: bold;
        }
        &:hover {
            color: $white;
            @include transition-basics(.2s, linear, 0s);
        }
        &:hover:before {
            color: $white;
            @include transition-basics(.2s, linear, 0s);
        }
    }
    .name {
        position: absolute;
        bottom: 8px;
        left: 8px;
        vertical-align: top;
        //right: 4px;
    }
}

.username {
    padding: 0.5em;
    border:1px dotted $medium-gray;
    margin-right:0.2em;
    &:before {
        font-family: 'FontAwesome';
        font-size: 1em;
        font-weight: 300;
        color: $dark-gray;
        content: '\f2bd';
        padding-right:0.5em;
    }
}

header .column,
header .columns {
    padding-left: 0rem;
    padding-right: 0rem;
}

//.grow { transition: all .2s ease-in-out; }
//.grow:hover { transform: scale(1.1); }
// #slide-content-body {
//      position: absolute;
//      top: 0;
//      left: 0;
//      width: 100%;
//      background-color: rgba($light-gray, 0.9);
//      padding-top: 15px;
//  }
