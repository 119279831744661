@charset 'utf-8';
@import 'settings';
@import 'foundation';
@import 'motion-ui';

@include foundation-global-styles;
//@include foundation-grid;
//@include foundation-float-classes;
@include foundation-flex-grid;
@include foundation-flex-classes;
@include foundation-typography;
@include foundation-forms;
@include foundation-button;
//@include foundation-accordion;
//@include foundation-accordion-menu;
@include foundation-badge;
//@include foundation-breadcrumbs;
@include foundation-button-group;
@include foundation-callout;
@include foundation-card;
@include foundation-close-button;
@include foundation-menu;
//@include foundation-menu-icon;
//@include foundation-drilldown-menu;
@include foundation-dropdown;
@include foundation-dropdown-menu;
@include foundation-responsive-embed;
@include foundation-label;
//@include foundation-media-object;
@include foundation-off-canvas;
//@include foundation-orbit;
//@include foundation-pagination;
//@include foundation-progress-bar;
//@include foundation-slider;
//@include foundation-sticky;
@include foundation-reveal;
//@include foundation-switch;
@include foundation-table;
@include foundation-tabs;
//@include foundation-thumbnail;
@include foundation-title-bar;
//@include foundation-tooltip;
//@include foundation-top-bar;
@include foundation-visibility-classes;


@include motion-ui-transitions;
@include motion-ui-animations;



@import url("https://fonts.googleapis.com/css?family=Droid+Sans:400,700|Ubuntu:400,700,700italic,400italic");
//@import url("/assets/foundation-icons/foundation-icons.css");


//mixins
@import "mixins/general";

//components
@import "components/historiana_mainstyles",
        "components/activitybuilder",
        "components/animations",
        "components/apps",
        "components/colors",
        "components/footer",
        "components/forms",
        "components/header",
        "components/index-cards",
        "components/typography";
        //"components/navigation",
        //"components/not-used";
        //"components/test-isotope";

//FIXES FOR FOUNDATION
.off-canvas-wrapper {
    height:100%;
}
