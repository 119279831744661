.slide-in-custom {
@include mui-slide(
$state: in,
$direction: right
//$amount: 50%
);
}

.slide-out-custom {
@include mui-slide(
$state: out,
$direction: left
//$amount: 50px
);
}

.hinge-in {
@include mui-hinge(
$state: in,
$from: top,
$fade: true,
$timing: map-get($motion-ui-easings, bounceInOut)
);
}

.hinge-out {
@include mui-hinge(
$state: out,
$from: top,
$fade: true,
$timing: map-get($motion-ui-easings, bounceInOut)
);
}